// Core
import React, { FC, ReactElement, useContext } from 'react';

// UI
import { DefaultGridContainer } from 'domains/ui/components/_styled/DefaultGridContainer';
import { Typography } from 'domains/ui/components/_styled/Text';
import { TextButton } from 'domains/ui/components/TextButton';
import { useBreakpoints } from 'domains/ui/hooks';
import { Container, BorderImage } from './styles';

import shpt from '../../assets/shpt.png';
import harvest from '../../assets/harvest.png';
import plywood from '../../assets/plywood.png';
import reshka from '../../assets/reshka.png';
import lev from '../../assets/lev.png';
import antytila from '../../../vendors/assets/antytila/logo_notblack.svg';

type CharityPartnersProps = {};

export const CharityPartners: FC<CharityPartnersProps> = ({}): ReactElement => {
    const { breakpoints } = useBreakpoints();
    const partners = [
        { img: shpt, link: '' },
        { img: antytila, link: '' },
        { img: reshka, link: '' },
        { img: lev, link: '' },
        { img: lev, link: '' },
        { img: plywood, link: '' },
        { img: harvest, link: '' },
        { img: harvest, link: '' },
        { img: harvest, link: '' },
    ];
    console.log(breakpoints, 'breakpoints');
    return (
        <DefaultGridContainer
            rowGap={breakpoints.xl ? 80 : 50}
            style={{ paddingTop: breakpoints.lg ? '150px' : '120px' }}
        >
            <Typography.H2 gridColumn='1 / -1' gridRow={1} placeSelf='end start'>
                Партнери
            </Typography.H2>

            <TextButton
                onClick={() => console.log('')}
                text='Інші лоти від партнерів'
                gridColumn='1 / -1'
                placeSelf={{
                    md: 'end',
                    xs: 'start center',
                }}
                gridRow={{
                    md: 1,
                    xs: 3,
                }}
                style={{
                    width: breakpoints.md ? 'auto' : '100%',
                }}
            />
            <Container style={{ gap: breakpoints.sm ? '20px 1vw' : '1vw' }}>
                {partners.map((item, id) => {
                    return (
                        <BorderImage
                            key={id}
                            onClick={() => console.log(item.link)}
                            style={{
                                width: id <= 1 ? '49%' : id <= 4 ? '32%' : '23.5%',
                                height: breakpoints.lg ? 200 : 100,
                                padding: breakpoints.md ? '20px' : '1vw',
                            }}
                        >
                            <img
                                alt=''
                                src={item.img}
                                style={{ maxHeight: breakpoints.lg ? 142 : 70 }}
                            />
                        </BorderImage>
                    );
                })}
            </Container>
        </DefaultGridContainer>
    );
};
