import React, { ReactElement, useEffect, useState } from 'react';
import Marquee from 'react-fast-marquee';
import { palette } from 'domains/ui/components/_mixins/_palette';

export const CharityCycledInfoYellow = (): ReactElement => {
    // const [position, setPostion] = useState(0);

    // useEffect(() => {
    //     setTimeout(() => {
    //         const element = document.getElementById('charity-auction-cards');
    //         var bodyRect = document.body.getBoundingClientRect();
    //         if (element) {
    //             var bodyRect = document.body.getBoundingClientRect(),
    //                 elemRect = element.getBoundingClientRect(),
    //                 offset = elemRect.bottom - bodyRect.top;

    //             setPostion(offset);

    //             const listener = () => {
    //                 setTimeout(() => {
    //                     var bodyRect = document.body.getBoundingClientRect(),
    //                         elemRect = element.getBoundingClientRect(),
    //                         offset = elemRect.bottom - bodyRect.top;
    //                     setPostion(offset);
    //                 }, 10);
    //             };
    //             window.addEventListener('resize', listener);
    //             return () => {
    //                 window.removeEventListener('resize', listener);
    //             };
    //         }
    //     }, 1000);
    // }, []);

    return (
        <Marquee
            style={{
                height: '55px',
                position: 'relative',
                marginTop: 180,
                background: palette.MainYellow,
                transform: 'rotate(2deg)',
                color: palette.MainBlack,
                textTransform: 'uppercase',
                whiteSpace: 'pre-wrap',
            }}
            gradient={false}
        >
            унікальні лоти від партнерів {'      ·      '} відомі та цікаві гості {'      ·      '}{' '}
            100% зібраних коштів йдуть на ЗСУ {'      ·      '} унікальні лоти від партнерів{' '}
            {'      ·      '} відомі та цікаві гості {'      ·      '} 100% зібраних коштів йдуть на
            ЗСУ {'      ·      '} унікальні лоти від партнерів {'      ·      '} відомі та цікаві
            гості {'      ·      '} 100% зібраних коштів йдуть на ЗСУ{'      ·      '}
        </Marquee>
    );
};
