// Core
import React, { FC, useEffect, useState } from 'react';

// UI
import { CircularProgress, Fade } from '@mui/material';

// Ctx
import { useNavigate, useParams } from 'react-router-dom';
import { palette } from 'domains/ui/components/_mixins/_palette';
import { DefaultGridContainer } from 'domains/ui/components/_styled/DefaultGridContainer';
import { useBreakpoints } from 'domains/ui/hooks';
import { AuctionGridContainer } from 'domains/ui/components/_styled/AuctionGrid';
import { AuctioCard } from 'domains/main/components';
import { AuctioCreateLot } from 'domains/main/components/AuctioCreateLot';
import { CardDetails } from 'domains/main/components/CardDetails';
import { delayedScrollingToTop } from 'utils/delayedScrolling';
import { useVendorLots } from 'domains/main/hooks/useVendorLots';
import { vendors } from 'domains/vendors/utils/vendors';
import { AppRoutes } from 'routes';
import { AuctioCardWithBanka } from 'domains/main/components/AuctioCardWithBanka';
import InfiniteScroll from 'react-infinite-scroll-component';

export const VendorMain: FC = () => {
    const navigate = useNavigate();
    const { id, vendorName } = useParams<{ id: string; vendorName: string }>();
    const { VendorCustomHeader, organizationName } =
        vendors[vendorName || '_fallback'] || vendors._fallback;

    const [filterValue] = useState('');

    const {
        breakpoints: { lg, sm, md },
    } = useBreakpoints();

    const { lots, showMoreAvailable, updatePage } = useVendorLots(organizationName);

    useEffect(() => {
        delayedScrollingToTop();
    }, []);

    useEffect(() => {
        if (!vendorName || !vendors[vendorName]) {
            navigate(AppRoutes.LANDING);
        }
    }, [vendorName, navigate]);

    const filteredLots = lots?.filter(
        ({ description, title }) =>
            title.toLowerCase().includes(filterValue.toLowerCase()) ||
            description.toLowerCase().includes(filterValue.toLowerCase()),
    );

    return (
        <Fade in>
            <DefaultGridContainer
                rowGap={80}
                style={{
                    paddingTop: 80,
                }}
            >
                <VendorCustomHeader />
                <AuctionGridContainer
                    gridColumn='1 / -1'
                    style={{
                        paddingBottom: 0,
                        marginTop: -40,
                    }}
                >
                    <InfiniteScroll
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: md ? 'space-between' : 'center',
                            rowGap: '30px',
                            overflow: 'hidden',
                        }}
                        dataLength={filteredLots.length}
                        next={updatePage}
                        hasMore={showMoreAvailable}
                        loader={
                            <CircularProgress
                                sx={{
                                    gridColumn: '1 / -1',
                                    gridRow: '1 / -1',
                                    placeSelf: 'center',
                                    color: palette.MainYellow,
                                }}
                            />
                        }
                    >
                        {filteredLots ? (
                            <>
                                {filteredLots?.map((el, index) =>
                                    el.type === 'LOTTERY' ? (
                                        <AuctioCardWithBanka
                                            key={el.id}
                                            gridColumn={`${1 + index * 4} / ${5 + index * 4}`}
                                            gridRow={2}
                                            background={index % 2 === 0 ? 'blue' : 'yellow'}
                                            isOwner={false}
                                            isDesktop
                                            {...el}
                                        />
                                    ) : (
                                        <AuctioCard
                                            key={el.id}
                                            gridColumn={`${1 + index * 4} / ${5 + index * 4}`}
                                            gridRow={2}
                                            background={index % 2 === 0 ? 'blue' : 'yellow'}
                                            isOwner={false}
                                            isDesktop
                                            {...el}
                                        />
                                    ),
                                )}
                                <AuctioCreateLot placeSelf='start' />

                                <div
                                    style={{
                                        minWidth: 365,
                                        maxWidth: 410,
                                        width: sm ? '100%' : '365px',
                                    }}
                                />
                            </>
                        ) : (
                            <CircularProgress
                                sx={{
                                    gridColumn: '1 / -1',
                                    gridRow: '1 / -1',
                                    placeSelf: 'center',
                                    color: palette.MainYellow,
                                }}
                            />
                        )}
                    </InfiniteScroll>
                </AuctionGridContainer>

                {id && <CardDetails id={id} background='yellow' isDesktop={lg} />}
            </DefaultGridContainer>
        </Fade>
    );
};
