import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    grid-column: 1 / -1;
    flex-wrap: wrap;
    justify-content: space-between;
`;
export const BorderImage = styled.div`
    height: 200px;
    border: 2px solid #262626;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    & img {
        max-width: 100%;

        transition: transform 0.1s ease;
    }
    &:hover {
        & img {
            transform: scale(0.9);
        }
    }
`;
