// Core
import React, { CSSProperties, FC, ReactElement } from 'react';
import { Container } from './styles';

// Instruments
import banner from '../../assets/banner.png';
import { Typography } from 'domains/ui/components/_styled/Text';
import { useBreakpoints } from 'domains/ui/hooks';
import { TextButton } from 'domains/ui/components/TextButton';
import { palette } from 'domains/ui/components/_mixins/_palette';
import { useNavigate } from 'react-router';
import { AppRoutes } from 'routes';

type CharityBannerProps = {
    className?: string;
    style?: CSSProperties;
    showCTA?: boolean;
};

export const CharityBanner: FC<CharityBannerProps> = ({ style = {}, showCTA }): ReactElement => {
    const navigate = useNavigate();
    const { breakpoints } = useBreakpoints();

    return (
        <Container
            style={{
                background: `url(${banner})`,
                marginTop: breakpoints.md ? 220 : 330,
                marginBottom: breakpoints.lg ? 150 : 100,
                gridTemplateColumns: breakpoints.xl
                    ? '2.5fr 1fr'
                    : breakpoints.md
                    ? '2fr 1fr'
                    : '1fr',
                gridTemplateRows: breakpoints.sm ? '' : '1.25fr 2fr 2.5fr',

                backgroundPositionX: breakpoints.md ? 'right' : '',
                ...style,
            }}
        >
            <Typography.Paragraph
                className='title'
                style={{
                    fontSize: breakpoints.lg ? 65 : breakpoints.md ? 52 : breakpoints.sm ? 32 : 28,
                }}
            >
                АУКЦІОН на підтримку зсу
            </Typography.Paragraph>
            <div>
                <Typography.Paragraph
                    className='when'
                    style={{
                        marginBottom: breakpoints.sm ? 25 : 15,
                    }}
                >
                    30 Березня о 18:00
                    <br />
                    Центр Шептицького УКУ, Львів
                </Typography.Paragraph>

                {showCTA && (
                    <TextButton
                        text='Дізнатися більше'
                        onClick={() => navigate(AppRoutes.CHARITY)}
                        overrideHoverColor={palette.MainWhite}
                        style={{
                            gap: breakpoints.sm ? undefined : 20,
                        }}
                    />
                )}
            </div>
            <Typography.Paragraph className='leftBorder'>
                <Typography.Span
                    style={{
                        fontSize: breakpoints.xs ? 20 : 16,
                    }}
                >
                    унікальні лоти від партнерів
                </Typography.Span>
                <Typography.Span style={{ padding: '10px 0', fontSize: breakpoints.xs ? 20 : 16 }}>
                    відомі та цікаві гості
                </Typography.Span>
                <Typography.Span
                    style={{
                        fontSize: breakpoints.xs ? 20 : 16,
                    }}
                >
                    100% зібраних коштів йдуть на ЗСУ
                </Typography.Span>
            </Typography.Paragraph>
        </Container>
    );
};
