// Core
import React, { FC } from 'react';

// UI
import { Fade, Grid } from '@mui/material';

// Ctx

import { useBreakpoints } from 'domains/ui/hooks';

import { CharityAbout } from 'domains/charity/components/CharityAbout';
import { CharityBanner } from 'domains/charity/components/CharityBanner';
import { CharityHeader } from 'domains/charity/components/CharityHeader';
import { CharityCycledInfo } from 'domains/charity/components/CharityCycledInfo';
import { CharityInfo } from 'domains/charity/components/CharityInfo';
import { CharityAuctionLots } from 'domains/charity/components/CharityAuctionLots';
import { CharityCycledInfoYellow } from 'domains/charity/components/CharityCycledInfoYellow';
import { WhatToDo } from 'domains/landing/components/WhatToDo';
import { CharityPartners } from 'domains/charity/components/CharityPartners';

export const CharityAuctionMain: FC = () => {
    const { breakpoints } = useBreakpoints();

    return (
        <Fade in>
            <Grid container style={{ marginBottom: breakpoints.lg ? '150px' : '120px' }}>
                <CharityHeader />
                <CharityBanner />
                <CharityAbout />
                <CharityCycledInfo />
                <CharityInfo />
                <CharityAuctionLots />
                <CharityCycledInfoYellow />
                <CharityPartners />
                <WhatToDo />
            </Grid>
        </Fade>
    );
};
