// Core
import React, {
    ChangeEvent,
    FC,
    MouseEvent,
    ReactElement,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// MUI
import { Backdrop, CircularProgress, IconButton, Tooltip } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import VerifiedIcon from '@mui/icons-material/Verified';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

// Context
import { AuthContext } from 'domains/auth/context/auth.context';
import { UiContext } from 'domains/ui/context/ui.context';
import { UserContext } from 'domains/profile/context/user.context';

// Instruments
import Carousel from 'react-material-ui-carousel';
import ReactMarkdown from 'markdown-to-jsx';
import { DateTime } from 'luxon';
import { useLots } from 'domains/main/hooks/useLots';
import { notifications } from 'utils/notifications';
import { Lot, LotStatus } from 'domains/main/types/lots.types';
import { palette } from 'domains/ui/components/_mixins/_palette';
import {
    CardDetailsBidControls,
    CardDetailsContainer,
    CardDetailsImage,
    CardDetailsLotData,
    CardLotDataText,
} from './styled';
import { Typography } from 'domains/ui/components/_styled/Text';
import { TextButton } from 'domains/ui/components/TextButton';
import { Input } from 'domains/ui/components/Input';
import { ShareDialog } from 'domains/ui/components/ShareDialog';
import { GridItem } from 'domains/ui/components/_styled/GridItem';
import { isLocationWithBackRoute } from 'utils/guards/isLocationWithBackRoute';
import { handleMobileShare } from 'utils/handleMobileShare';
import { useBreakpoints, useButtonActions } from 'domains/ui/hooks';
import { AppRoutes } from 'routes';
import { ConfirmBid } from '../ConfirmBid';
import CloseIcon from '@mui/icons-material/Close';
import { DetailsImagePopup } from '../DetailsImagePopup';
import { useLotInfo } from 'domains/main/hooks/useLotInfo';
import { MonoBankaInstructions } from '../MonoBankaInstructions';
import { DescriptionLink } from 'domains/ui/components/DescriptionLink';

const MIN_BID_DELTA_LOW = 50;
const bidsPresetLow = [100, 300, 500, 1000, 1500];

const MIN_BID_DELTA_HIGH = 1000;
const bidsPresetHigh = [1000, 2000, 3000, 4000, 5000];

type CardDetailsProps = {
    id: string;
    isDesktop: boolean;
    background: 'yellow' | 'blue';
};

export const CardDetails: FC<CardDetailsProps> = ({ id, background }): ReactElement => {
    const navigate = useNavigate();
    const location = useLocation();
    const { isMobile, isTablet, breakpoints } = useBreakpoints();

    const [alternativePaymentInstructions, setAlternativePaymentInstructionsState] =
        useState(false);

    const { isAuthenticated } = useContext(AuthContext);
    const { setOtpDialogState } = useContext(UiContext);
    const { userData } = useContext(UserContext);
    const { lot, fetchLot, fetchedLotData } = useLotInfo();

    const [cardData, setCardData] = useState<Lot>();
    const [shareOpen, setShareOpenState] = useState(false);
    const [openImage, setOpenImageState] = useState<number | null>(null);
    const [confirmBidPopup, setConfirmBidPopupState] = useState(false);
    const [bid, setBid] = useState('');

    const { handleOpenLotUpdate, handleOpenLotFeedback, openAuction } = useButtonActions();
    const { biddingInProgress } = useLots();

    const isOwner = cardData?.creator.id === userData?.id;

    const currentBidDelta =
        cardData?.price && cardData?.price >= 20000 ? MIN_BID_DELTA_HIGH : MIN_BID_DELTA_LOW;

    const bidsPreset = cardData?.price && cardData?.price >= 20000 ? bidsPresetHigh : bidsPresetLow;

    useEffect(() => {
        document.body.style.overflowY = 'hidden';

        return () => {
            document.body.style.overflowY = 'scroll';
        };
    }, []);

    useEffect(() => {
        if (!fetchedLotData) {
            fetchLot(id);
        }
    }, [id, fetchedLotData]);

    useEffect(() => {
        if (fetchedLotData && lot && lot.id) {
            setCardData(lot);
        } else if (fetchedLotData) {
            openAuction();
        }
    }, [lot, fetchedLotData]);

    const { title, creator, description, price, endDate, images, isLastBidder } =
        cardData || ({} as Lot);

    const openShareDialog = (e: MouseEvent) => {
        e.stopPropagation();
        setShareOpenState(true);
    };

    const closeShareDialog = (e: MouseEvent) => {
        e.stopPropagation();
        setShareOpenState(false);
    };

    const closeConfirmBidDialog = (e: MouseEvent) => {
        e.stopPropagation();
        setConfirmBidPopupState(false);
    };

    const handleShareClick = (e: MouseEvent) => {
        if (isMobile || isTablet) {
            handleMobileShare(
                e,
                {
                    shareLink: `${window.location.href}`,
                    shareText: `Аукціон "Лоти проти Сволоти" - ${title} від ${creator.firstName} ${creator.lastName}\n`,
                },
                openShareDialog,
            );
        } else {
            openShareDialog(e);
        }
    };
    const handleSetBid = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event?.target;

        if (/^[0-9]*$/.test(value) && Number(bid) < Number.MAX_SAFE_INTEGER) {
            setBid(value);
        }
    };

    const handleSetPreparedBids = (incr: number) => {
        setBid((prev) => (Number(prev.length ? prev : price) + incr).toFixed(0));
    };

    const handleBidding = async () => {
        if (biddingInProgress) return null;
        if (!bid) return null;
        if (Number(bid) - price < currentBidDelta)
            return notifications.warning(`Мінімальний крок - ${currentBidDelta} грн.`);
        if (!isAuthenticated) return setOtpDialogState(true);

        setConfirmBidPopupState(true);
    };

    const handleGoBack = () => {
        if (isLocationWithBackRoute(location)) {
            navigate(location.state.from);
        } else {
            navigate(window.location.href);
        }
    };

    const toEndDate = DateTime.fromJSDate(new Date(endDate))
        .diffNow(['days', 'hours', 'minutes'])
        .mapUnits((unit) => Math.round(unit))
        .toObject();

    const auctionFinished = (Object.keys(toEndDate) as (keyof typeof toEndDate)[]).every(
        (el) => Number(el) <= 0,
    );
    const openImagePopup = (idx: number) => {
        breakpoints.md && setOpenImageState(idx);
    };

    const getButtonData = () => {
        if (cardData?.type === 'LOTTERY') {
            return {
                text: 'Відкрити банку',
                onClick: () => setAlternativePaymentInstructionsState(true),
            };
        }

        // Owner's button data
        if (cardData?.status === LotStatus.PAID && isOwner)
            return {
                text: 'Контакти переможця',
                onClick: () =>
                    notifications.info('Контактна інформація про переможця була вислана в СМС'),
            };

        if (isOwner)
            return {
                text: 'Редагувати',
                onClick: handleOpenLotUpdate,
            };

        // Client's button data
        if (cardData?.status === LotStatus.PAID && !isOwner)
            return {
                text: 'Поскаржитися на лот',
                onClick: handleOpenLotFeedback,
            };

        if (!isOwner && isLastBidder)
            return {
                text: 'Ставку прийнято',
                onClick: () => null,
            };

        // Default
        return {
            text: 'Підняти ставку',
            onClick: handleBidding,
            disabled: !bid.length || (cardData?.price && Number(bid) <= cardData.price),
        };
    };

    return (
        <Backdrop
            open
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: '#000000e9',
            }}
            onClick={handleGoBack}
        >
            {cardData ? (
                <CardDetailsContainer background={background} onClick={(e) => e.stopPropagation()}>
                    <IconButton
                        aria-label='logout'
                        onClick={handleGoBack}
                        style={
                            breakpoints.sm
                                ? { position: 'absolute', zIndex: 1, right: 10, top: 30 }
                                : { position: 'absolute', zIndex: 1, right: 0, top: 0 }
                        }
                    >
                        <Tooltip title='Закрити'>
                            <CloseIcon style={{ color: '#1A1A1A', width: 35, height: 35 }} />
                        </Tooltip>
                    </IconButton>
                    <CardDetailsLotData>
                        <Carousel
                            className='carousele-small'
                            height='100%'
                            navButtonsAlwaysInvisible={images.length === 1}
                            navButtonsAlwaysVisible={images.length > 1}
                            interval={5000}
                            navButtonsWrapperProps={{
                                style: { opacity: 0.5, height: 'max-content', top: '40%' },
                            }}
                        >
                            {images.map((item, idx) => (
                                <CardDetailsImage
                                    color={item?.url}
                                    key={idx}
                                    style={{
                                        backgroundImage: `url(${item?.url})`,
                                    }}
                                    onClick={() => {
                                        openImagePopup(idx);
                                    }}
                                >
                                    {breakpoints.md && (
                                        <ZoomInIcon
                                            onClick={() => openImagePopup(idx)}
                                            fontSize='large'
                                        />
                                    )}
                                </CardDetailsImage>
                            ))}
                        </Carousel>

                        {breakpoints.md && openImage !== null ? (
                            <DetailsImagePopup
                                close={() => setOpenImageState(null)}
                                imageId={openImage}
                                images={images}
                            />
                        ) : (
                            <></>
                        )}

                        <Typography.ProductCardName
                            gridColumn={{
                                xl: '2 / -1',
                                lg: '2 / -1',
                                md: '2 / -1',
                                sm: 1,
                                xs: 1,
                            }}
                            style={{
                                maxHeight: 75,
                                height: 'auto',
                            }}
                        >
                            {title}
                        </Typography.ProductCardName>

                        <GridItem
                            style={{
                                display: 'grid',
                                width: '100%',
                                gap: 20,
                            }}
                        >
                            <CardLotDataText>
                                <Typography.ProductCardInfoBold>
                                    До закриття лоту:
                                </Typography.ProductCardInfoBold>
                                <Typography.ProductCardInfoBold style={{ textAlign: 'end' }}>
                                    {auctionFinished
                                        ? 'Закінчено'
                                        : `${toEndDate.days ? `${toEndDate.days}д` : ''}\xa0${
                                              toEndDate.hours ? `${toEndDate.hours}год` : ''
                                          }\xa0${
                                              toEndDate.minutes ? `${toEndDate.minutes}хв` : ''
                                          }`}
                                </Typography.ProductCardInfoBold>
                            </CardLotDataText>

                            <CardLotDataText>
                                <Typography.ProductCardInfoBold>
                                    Бенефіціар:
                                </Typography.ProductCardInfoBold>
                                <Typography.ProductCardInfoBold
                                    style={{ textAlign: 'end', fontWeight: 'bold' }}
                                >
                                    {cardData.merchant.name}
                                </Typography.ProductCardInfoBold>
                            </CardLotDataText>

                            <CardLotDataText>
                                <Typography.ProductCardInfoBold style={{ fontWeight: 'bold' }}>
                                    {cardData?.type === 'LOTTERY'
                                        ? 'Вартість квитка'
                                        : 'Найвища ставка:'}
                                </Typography.ProductCardInfoBold>
                                <Typography.ProductCardInfoBold
                                    style={{ textAlign: 'end', fontWeight: 'bold' }}
                                >
                                    ₴ {price}
                                </Typography.ProductCardInfoBold>
                            </CardLotDataText>

                            <CardLotDataText>
                                <Typography.ProductCardInfoBold>
                                    Автор:
                                </Typography.ProductCardInfoBold>
                                <Typography.ProductCardInfoBold
                                    style={{
                                        cursor:
                                            creator.id === 'da5b6aab-06ba-4de8-b8d4-38e1083425cc'
                                                ? 'pointer'
                                                : 'default',
                                        textAlign: 'end',
                                        display: 'flex',
                                        alignItems: 'start',
                                        gap: 5,
                                    }}
                                    onClick={() => {
                                        if (creator.id === 'da5b6aab-06ba-4de8-b8d4-38e1083425cc') {
                                            navigate(`${AppRoutes.APP}/antytila`);
                                        }
                                    }}
                                >
                                    {creator.isVerified && (
                                        <>
                                            <Tooltip
                                                title={
                                                    <Typography.Tooltip>
                                                        Підтверджений профіль
                                                    </Typography.Tooltip>
                                                }
                                            >
                                                <VerifiedIcon
                                                    sx={{
                                                        color: palette.MainBlack,
                                                    }}
                                                />
                                            </Tooltip>
                                        </>
                                    )}
                                    {creator.firstName} {creator.lastName}
                                </Typography.ProductCardInfoBold>
                            </CardLotDataText>
                        </GridItem>
                    </CardDetailsLotData>

                    <CardDetailsBidControls>
                        <Typography.Paragraph
                            withScroll
                            gridColumn={'1 / -1'}
                            style={{
                                color: palette.MainBlack,
                                height: '100%',
                                overflowY: 'scroll',
                                whiteSpace: 'pre-line',
                                maxHeight: 180,
                            }}
                        >
                            <ReactMarkdown
                                options={{
                                    overrides: {
                                        a: {
                                            component: DescriptionLink,
                                        },
                                    },
                                    wrapper: React.Fragment,
                                    forceInline: true,
                                }}
                            >
                                {/* Replacing `*` with `**` to make markdown plugin work correctly  */}
                                {description.replaceAll('*', '**')}
                            </ReactMarkdown>
                        </Typography.Paragraph>

                        {cardData?.type === 'LOTTERY' ? (
                            <div />
                        ) : (
                            <GridItem
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    gap: 10,
                                    flexWrap: 'wrap',
                                }}
                            >
                                <Input
                                    value={bid}
                                    onChange={handleSetBid}
                                    placeholder='Ваша ставка'
                                    style={{ height: 35, fontSize: 14, width: 120 }}
                                    dark
                                />

                                {bidsPreset.map((el) => (
                                    <TextButton
                                        key={el}
                                        text={`+${el} грн`}
                                        onClick={() => handleSetPreparedBids(el)}
                                        style={{ height: 35, fontSize: 14 }}
                                        overrideHoverColor={palette.MainBlue}
                                        dark
                                        hideArrow
                                    />
                                ))}
                            </GridItem>
                        )}

                        {!auctionFinished && (
                            <IconButton
                                aria-label='settings'
                                onClick={handleShareClick}
                                sx={{
                                    placeSelf: 'end start',
                                    gridColumn: 1,
                                    gridRow: 3,

                                    '&:hover': {
                                        background: '#f0f0f0',
                                    },
                                }}
                            >
                                <Tooltip title={<Typography.Tooltip>Поширити</Typography.Tooltip>}>
                                    <ShareIcon htmlColor={palette.MainBlack} />
                                </Tooltip>
                            </IconButton>
                        )}

                        <TextButton
                            onClick={getButtonData().onClick}
                            text={getButtonData().text}
                            disabled={getButtonData().disabled || isLastBidder}
                            placeSelf='end'
                            gridRow={3}
                            gridColumn={1}
                            hideArrow
                            dark
                            style={{
                                height: 40,
                                fontWeight: 'bold',
                            }}
                            overrideHoverColor={palette.MainBlue}
                        />
                    </CardDetailsBidControls>
                </CardDetailsContainer>
            ) : (
                <CircularProgress
                    sx={{
                        color: palette.MainYellow,
                    }}
                />
            )}

            {confirmBidPopup && (
                <ConfirmBid
                    id={id}
                    closeConfirmBid={closeConfirmBidDialog}
                    cardData={cardData}
                    bid={bid}
                />
            )}
            {cardData && (
                <ShareDialog
                    inProp={shareOpen}
                    shareLink={`${window.location.href}`}
                    shareText={`Аукціон "Лоти проти Сволоти" - ${title} від ${creator.firstName} ${creator.lastName}\n`}
                    closeDialog={closeShareDialog}
                />
            )}

            {alternativePaymentInstructions && cardData && (
                <MonoBankaInstructions
                    additionalInfo={cardData.additionalInfo}
                    merchant={cardData.merchant}
                    closeMonoBankaInstructions={() => setAlternativePaymentInstructionsState(false)}
                    lotName={title}
                    price={price}
                    id={id}
                />
            )}
        </Backdrop>
    );
};
