// Core
import React, { FC, ReactElement, useContext } from 'react';

// MUI / Styles
import { Typography } from 'domains/ui/components/_styled/Text';

// Instruments
import { useBreakpoints } from 'domains/ui/hooks';
import { getEndingLotWord } from 'utils/getEndingLotWord';
import { UiContext } from 'domains/ui/context/ui.context';

type ResultsCountersProps = { styles?: any };

export const ResultsCounters: FC<ResultsCountersProps> = ({ styles }): ReactElement => {
    const {
        breakpoints: { lg },
    } = useBreakpoints();
    const { dashboardStats } = useContext(UiContext);
    const styleFontSizeTitle = lg ? { fontSize: 30 } : { fontSize: 18 };

    return dashboardStats ? (
        <div style={styles}>
            {dashboardStats?.paidLotsSum ? (
                <>
                    <Typography.DecorativeLabel gridColumn={1} style={styleFontSizeTitle}>
                        {dashboardStats?.paidLotsSum || 0}₴
                        <Typography.Span
                            style={
                                lg
                                    ? { fontSize: 24, textTransform: 'lowercase' }
                                    : { fontSize: 18, textTransform: 'lowercase' }
                            }
                        >
                            зібрано на підтримку армії
                        </Typography.Span>
                    </Typography.DecorativeLabel>

                    <Typography.DecorativeLabel gridColumn={1} style={styleFontSizeTitle}>
                        {dashboardStats?.paidLotsCount || 0} лот
                        {getEndingLotWord(dashboardStats?.paidLotsCount)}
                        <Typography.Span
                            style={
                                lg
                                    ? { fontSize: 24, textTransform: 'lowercase' }
                                    : { fontSize: 18, textTransform: 'lowercase' }
                            }
                        >
                            розіграно на аукціоні
                        </Typography.Span>
                    </Typography.DecorativeLabel>
                </>
            ) : (
                <Typography.DecorativeLabel gridColumn={1} style={styleFontSizeTitle}>
                    28
                    <Typography.Span
                        style={
                            lg
                                ? { fontSize: 24, textTransform: 'lowercase' }
                                : { fontSize: 18, textTransform: 'lowercase' }
                        }
                    >
                        лотів розміщено
                    </Typography.Span>
                </Typography.DecorativeLabel>
            )}

            <Typography.DecorativeLabel gridColumn={1} style={styleFontSizeTitle}>
                {dashboardStats?.registeredUsers || 0} осіб
                <Typography.Span
                    style={
                        lg
                            ? { fontSize: 24, textTransform: 'lowercase' }
                            : { fontSize: 18, textTransform: 'lowercase' }
                    }
                >
                    долучились до ініціативи
                </Typography.Span>
            </Typography.DecorativeLabel>
        </div>
    ) : (
        <></>
    );
};
