import { useCallback, useEffect, useState } from 'react';
import { logger } from 'utils/logger';
import { LotsApi } from '../api';
import { Lot, LotsRequest } from '../types/lots.types';

export const useVendorLots = (nameOrganization: string) => {
    const [lots, setLots] = useState<Lot[]>([]);
    const [organizationId, setOrganizationId] = useState<string | undefined>();
    const [isLoading, setisLoading] = useState<boolean>(false);

    const [showMoreAvailable, setshowMoreAvailable] = useState<boolean>(false);
    const limit = 20;
    const scrollElementsAdd = 6;

    const [page, setPage] = useState<number>(0);
    const updPage = page + 1;
    const pageMore1 = updPage > 1;

    const fetchArchiveAsync = useCallback(
        async (lotsRequestData: LotsRequest) => {
            try {
                const {
                    data: { lots: newLots, count },
                } = await LotsApi.fetchLots({
                    ...lotsRequestData,
                    organizationId,

                    orderBy: 'end-date',
                    order: 'desc',
                });
                setLots([...lots, ...newLots]);
                setshowMoreAvailable((page + 1) * limit < count);
            } catch (error) {
                logger.error('Помилка завантаження лотів', true, error);
            }
        },
        [page, lots, organizationId],
    );

    const fetchOrganizationsAsync = useCallback(async () => {
        try {
            const { data } = await LotsApi.fetchOrganizations();

            setOrganizationId(data.find((item) => item.name === nameOrganization)?.id);
        } catch (error) {
            logger.error('Помилка завантаження організацій', true, error);
        }
    }, []);

    useEffect(() => {
        if (organizationId) {
            if (page !== 0) {
                setPage(0);
            }
            setLots([]);

            fetchArchiveAsync({ limit });
        }
    }, [organizationId]);

    useEffect(() => {
        if (page > 0 && organizationId) {
            if (pageMore1) {
                console.log(limit, updPage - 2, scrollElementsAdd, '!!!!!');
                fetchArchiveAsync({
                    skip: limit + (updPage - 2) * scrollElementsAdd,
                    limit: scrollElementsAdd,
                });
            } else {
                fetchArchiveAsync({ skip: page * limit, limit });
            }
        }
    }, [page, organizationId]);

    useEffect(() => {
        fetchOrganizationsAsync();
    }, []);

    const updatePage = () => {
        setPage(page + 1);
    };

    return {
        lots,
        fetchArchive: fetchArchiveAsync,
        updatePage,
        showMoreAvailable,
    };
};
