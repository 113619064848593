import { RedirectPage } from 'domains/ui/pages/Redirect';
import { FC } from 'react';
import { AppRoutes } from 'routes';
import { AntytilaHeader } from '../components/antytila/AntytilaHeader';
import { PivnichHeader } from '../components/pivnich/PivnichHeader';
import { UwvmHeader } from '../components/uwvm/UwvmHeader';
import { ZgrayaHeader } from '../components/zgraya/ZgrayaHeader';

type VendorPageData = {
    organizationName: string;
    VendorCustomHeader: FC;
};

export const vendors: Record<string, VendorPageData> = {
    antytila: {
        organizationName: 'Antytila Fund',
        VendorCustomHeader: AntytilaHeader,
    },
    zgraya: {
        organizationName: 'Zgraya',
        VendorCustomHeader: ZgrayaHeader,
    },
    pivnich: {
        organizationName: 'V.Pivnich',
        VendorCustomHeader: PivnichHeader,
    },
    uwvm: {
        organizationName: 'VETERANKA',
        VendorCustomHeader: UwvmHeader,
    },

    _fallback: {
        organizationName: '',
        VendorCustomHeader: () => <RedirectPage to={AppRoutes.LANDING} />,
    },
};
