import React, { ReactElement, useEffect, useState } from 'react';
import { palette } from 'domains/ui/components/_mixins/_palette';
import { Container } from './styles';

export const CharityCycledInfo = (): ReactElement => {
    // const [position, setPostion] = useState(0);

    // useEffect(() => {
    //     const element = document.getElementById('about-charity');
    //     var bodyRect = document.body.getBoundingClientRect();
    //     if (element) {
    //         var bodyRect = document.body.getBoundingClientRect(),
    //             elemRect = element.getBoundingClientRect(),
    //             offset = elemRect.bottom - bodyRect.top;

    //         setPostion(offset - offset * 0.005);

    //         const listener = () => {
    //             setTimeout(() => {
    //                 var bodyRect = document.body.getBoundingClientRect(),
    //                     elemRect = element.getBoundingClientRect(),
    //                     offset = elemRect.bottom - bodyRect.top;
    //                 setPostion(offset - offset * 0.025);
    //             }, 10);
    //         };
    //         window.addEventListener('resize', listener);
    //         return () => {
    //             window.removeEventListener('resize', listener);
    //         };
    //     }
    // }, []);
    return (
        <Container
            style={{
                height: '55px',
                position: 'relative',
                background: palette.MainBlue,
                transform: 'rotate(-2deg)',
                whiteSpace: 'pre-wrap',
                gap: '30px',
            }}
            gradient={false}
        >
            <span>30 Березня о 18:00</span>
            Центр Шептицького, УКУ
            {'        ·'}
            <span>30 Березня о 18:00</span>
            Центр Шептицького, УКУ
            {'        ·'}
            <span>30 Березня о 18:00</span>
            Центр Шептицького, УКУ
            {'        ·'}
        </Container>
    );
};
