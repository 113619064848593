// Core
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// MUI
import { Fade, Grid } from '@mui/material';
import { Greeting } from './components/Greeting';
import { HowItWorks } from './components/HowItWorks';
import { Showcase } from './components/Showcase';
import { WhatToDo } from './components/WhatToDo';
import { Results } from './components/Results';
import { LandingSocials } from './components/LandingSocials';
import { useBreakpoints } from 'domains/ui/hooks';
import { delayedScrollingToTop, delayedScrollingToHash } from 'utils/delayedScrolling';
import { getQueryVariable } from 'utils/getQueryVariable';
import { CharityBanner } from 'domains/charity/components/CharityBanner';

export const LandingPage: FC = (): ReactElement => {
    const location = useLocation();
    const { breakpoints } = useBreakpoints();
    const [openedLot, setopenedLot] = useState(false);

    useEffect(() => {
        if (location.hash) {
            delayedScrollingToHash(location.hash);
        } else {
            if (!openedLot) {
                delayedScrollingToTop();
            }
        }

        const id = getQueryVariable('id');
        setopenedLot(!!id);
    }, [location]);

    return (
        <Fade in>
            <Grid container style={{ marginBottom: breakpoints.lg ? '150px' : '120px' }}>
                {/* Temp */}
                <CharityBanner
                    style={{
                        marginTop: 50,
                        marginBottom: -50,
                    }}
                    showCTA
                />
                <Greeting />

                <Showcase type='current' />
                <HowItWorks />
                <LandingSocials />
                <Results />

                {/* <Invitation /> */}
                <WhatToDo />
                {/* <Showcase type='archive' /> */}
            </Grid>
        </Fade>
    );
};
