import styled from 'styled-components';
import Marquee from 'react-fast-marquee';

export const Container = styled(Marquee)`
    display: flex;
    align-items: center;
    text-transform: uppercase;

    & p {
        text-align: center;
    }
    & span {
        font-weight: 700;
    }
`;
