// Core
import React, { FC, useMemo } from 'react';

// UI
import { DefaultGridContainer } from 'domains/ui/components/_styled/DefaultGridContainer';
import { Typography } from 'domains/ui/components/_styled/Text';
import { useBreakpoints } from 'domains/ui/hooks';
import { AuctionLotsCarousel } from 'domains/ui/components/AuctionLotsCarousel';
import { palette } from 'domains/ui/components/_mixins/_palette';
import { useVendorLots } from 'domains/main/hooks/useVendorLots';

type CharityAuctionLotsProps = {
    className?: string;
};

export const CharityAuctionLots: FC<CharityAuctionLotsProps> = () => {
    const { lots } = useVendorLots('UCU_Offline');
    const { breakpoints } = useBreakpoints();
    // const { openAuction } = useButtonActions();

    const lotteryLots = useMemo(() => lots.filter((lot) => lot.type === 'LOTTERY'), [lots]);

    const biddableLots = useMemo(() => lots.filter((lot) => lot.type === 'BIDDABLE'), [lots]);

    return Boolean(lots.length) ? (
        <DefaultGridContainer
            id='charity-auction-cards'
            rowGap={breakpoints.xl ? 80 : 50}
            style={{
                paddingTop: breakpoints.lg ? '150px' : '120px',
                paddingBottom: breakpoints.lg ? '150px' : '120px',
            }}
        >
            {Boolean(lotteryLots.length) && (
                <>
                    <Typography.H2 gridColumn='1 / -1' placeSelf='end start'>
                        Що будемо розігрувати
                    </Typography.H2>
                    <Typography.H3
                        style={{ color: palette.MainWhite }}
                        gridColumn='1 / -1'
                        placeSelf='end start'
                    >
                        Лоти-Банки
                    </Typography.H3>
                    <AuctionLotsCarousel
                        data={lotteryLots}
                        gridColumn='1 / -1'
                        withBlockFullPage={false}
                    />
                </>
            )}

            {Boolean(biddableLots.length) && (
                <>
                    {' '}
                    <Typography.H3
                        style={{ color: palette.MainWhite }}
                        gridColumn='1 / -1'
                        placeSelf='end start'
                    >
                        Лоти для аукціону
                    </Typography.H3>
                    <AuctionLotsCarousel
                        data={biddableLots}
                        gridColumn='1 / -1'
                        withBlockFullPage={false}
                    />
                </>
            )}
        </DefaultGridContainer>
    ) : null;
};
